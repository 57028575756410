/** @format **/

import React, { useRef, useState, useCallback, MutableRefObject, useEffect } from 'react';
import { isNil } from 'lodash';

import { Flex } from 'components/flex';
import { IconButton } from 'components/ds/button';

import { OverflowWrapper, NavButtonWrapper } from './components';

type Props = {
  scrollStep: number;
  mediumOnly?: boolean;
  onPrevClick?: (scrollLeft: number) => void;
  onNextClick?: (scrollLeft: number) => void;
};

export const HorizontalScroll: React.FunctionComponent<Props> = ({
  children,
  scrollStep,
  mediumOnly,
  onNextClick,
  onPrevClick,
}) => {
  const containerRef: MutableRefObject<any> = useRef({
    current: {
      scrollLeft: 0,
      scrollWidth: null,
      offsetWidth: null,
    },
  });
  const [disabledButtons, setDisabledButtons] = useState([false, false]);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  const showButtons = () => {
    const { current } = containerRef;
    const containerScrollWidth = current.scrollWidth;
    const containerOffsetWidth = current.offsetWidth;

    setButtonsVisible(containerScrollWidth > containerOffsetWidth);
  };

  useEffect(() => {
    showButtons();
  }, []);

  const getNextScrollPosition = (increase: boolean): number => {
    const multiplier = increase ? 1 : -1;
    const scrollLeft = containerRef.current.scrollLeft;
    const newValue = scrollLeft + scrollStep * multiplier;

    return Math.floor(Math.max(0, newValue));
  };

  const isButtonDisabled = (isNext: boolean = false) => {
    const { current } = containerRef;
    const currentLeft = current.scrollLeft;
    const containerScrollWidth = current.scrollWidth;
    const containerOffsetWidth = current.offsetWidth;

    if (!isNext && isNil(currentLeft) && isNil(containerScrollWidth)) {
      return true;
    }

    return (
      (isNext && currentLeft >= containerScrollWidth - containerOffsetWidth) ||
      (!isNext && currentLeft <= 0)
    );
  };

  const updateDisabledButtons = () => {
    setDisabledButtons([isButtonDisabled(), isButtonDisabled(true)]);
  };

  const getNavButton = (isNext: boolean = false) => {
    const [prevDisabled, nextDisabled] = disabledButtons;

    return (
      <IconButton
        icon={isNext ? 'chevron-right' : 'chevron-left'}
        disabled={isButtonDisabled(isNext) || (isNext && nextDisabled) || (!isNext && prevDisabled)}
        onClick={useCallback(() => {
          const nextScrollPosition = getNextScrollPosition(isNext);
          containerRef.current.scrollLeft = nextScrollPosition;
          updateDisabledButtons();

          if (isNext && !isNil(onNextClick)) {
            onNextClick(nextScrollPosition);
          }

          if (!isNext && !isNil(onPrevClick)) {
            onPrevClick(nextScrollPosition);
          }
        }, [])}
      />
    );
  };

  return (
    <Flex>
      <NavButtonWrapper
        mediumOnly={mediumOnly}
        buttonsVisible={buttonsVisible}
        aria-hidden={!buttonsVisible}
      >
        {getNavButton()}
      </NavButtonWrapper>
      <OverflowWrapper
        onScroll={updateDisabledButtons}
        innerRef={(containerRef as unknown) as (instance: any) => void}
      >
        {children}
      </OverflowWrapper>
      <NavButtonWrapper
        mediumOnly={mediumOnly}
        buttonsVisible={buttonsVisible}
        aria-hidden={!buttonsVisible}
      >
        {getNavButton(true)}
      </NavButtonWrapper>
    </Flex>
  );
};
