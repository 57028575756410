/**
 * @prettier
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import 'app/polyfills';
import Notifications from 'app/notifications';

import { ErrorBoundary } from 'components/errorBoundary';

import { App, AuthenticationState } from './app';
import { Authentication } from './authentication';

ReactDOM.render(
  <App>
    <ErrorBoundary>
      <Notifications />
      <Authentication />
    </ErrorBoundary>
  </App>,
  document.getElementById('root'),
);

export { AuthenticationState };
