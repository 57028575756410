/** @format **/

import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { nestedReducerUpdate, reducerUpdate } from 'utils/reducerUpdate';

import * as Actions from '../actions';
import { SignInState } from '../state';

const initialState: SignInState = {
  fetching: {
    error: null,
    hasErrored: false,
    isFetching: false,
  },
  banner: {
    bannerId: '',
    bannerImgSrc: '',
    bannerImgHeader: '',
    bannerImgDescription: '',
    bannerLink: '',
    bannerLinkText: '',
    darkTheme: false,
  },
};

export const SignInReducer = reducerWithInitialState(initialState)
  .case(Actions.signInBannerFetchingStarted, state =>
    nestedReducerUpdate(state, 'fetching', {
      isFetching: true,
      hasErrored: false,
      error: null,
    }),
  )
  .case(Actions.updateSignInBanner, (state, signInBanner) => {
    const parsedBanner = typeof signInBanner === 'string' ? JSON.parse(signInBanner) : signInBanner;
    parsedBanner.bannerImgSrc = `https://raygun.com/${parsedBanner.bannerImgSrc}`;

    return reducerUpdate(state, {
      banner: parsedBanner,
    });
  })
  .case(Actions.signInBannerFetchingSucceeded, state =>
    nestedReducerUpdate(state, 'fetching', {
      error: null,
      hasErrored: false,
      isFetching: false,
    }),
  );