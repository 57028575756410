/** @format **/
import React, { FunctionComponent } from 'react';

import { isNotNilOrEmpty } from 'utils/string';

import { H2, Link } from "components/ds/text";
import { Padding } from 'components/layout';
import { getTextAlignmentClass } from 'components/text';
import { SnowplowLink } from "components/snowplow";

import { SignInBanner } from '../models';
import {
  DescriptionText,
  RightContainer,
  RightContainerWrapper,
  SignInAdvertImage,
  SignInAdvertImagePlaceholder,
} from './styles';

type Props = {
  bannerData: SignInBanner;
};

export const RightSection: FunctionComponent<Props> = props => {
  const snowplowId = `sp-app-auth-signin-${props.bannerData.bannerId.replace(/ /g, '-')}`;
  
  return (
    <RightContainer darkTheme={props.bannerData.darkTheme}>
      <RightContainerWrapper>
        <SnowplowLink interactionId={`${snowplowId}-clickedImage`}>
          <a href={props.bannerData.bannerLink} target="_blank">
            {isNotNilOrEmpty(props.bannerData.bannerImgSrc) ? (
              <SignInAdvertImage
                src={props.bannerData.bannerImgSrc}
                alt={props.bannerData.bannerImgHeader}
                height={'437px'}
                width={'437px'}
              />
            ) : (
              <SignInAdvertImagePlaceholder />
            )}
          </a>
        </SnowplowLink>
  
        <Padding top={'42'}>
          <div className={getTextAlignmentClass('center')}>
            <H2 color={props.bannerData.darkTheme ? 'white' : 'grey800'}>{props.bannerData.bannerImgHeader}</H2>
  
            <Padding top={'16'} bottom={'24'}>
              <DescriptionText darkTheme={props.bannerData.darkTheme}>{props.bannerData.bannerImgDescription}</DescriptionText>
            </Padding>

            <SnowplowLink interactionId={`${snowplowId}-clickedLink`}>
              <Link
                color={props.bannerData.darkTheme ? 'blue600' : 'blue1000'}
                href={props.bannerData.bannerLink}
                fontWeight={600}
                target='_blank'
              >
                {props.bannerData.bannerLinkText}
              </Link>
            </SnowplowLink>
          </div>
        </Padding>
      </RightContainerWrapper>
    </RightContainer>
  );
};