/** @format **/
import React from 'react';

import { Tab, Tabs } from 'components/ds/tabs';
import { H1 } from 'components/ds/text';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Margin, Padding } from 'components/layout';

import { StartTrialModule } from './startTrialModule';
import {
  LeftContainer,
  LeftContainerWrapper,
  LogoImage,
  SSOLinkWrapper,
  SignInFormContainer,
  SignInLink,
  TitleWrapper,
} from './styles';
import { SignInForm, SocialSignIn } from './tabContent';

export const LeftSection: React.FunctionComponent = () => (
  <LeftContainer>
    <LeftContainerWrapper>
      <Flex justify="space-between" align="center">
        <LogoImage alt="Raygun logo" src="/Content/Images/logo/raygun-modern.svg" />
        <StartTrialModule />
      </Flex>
      <TitleWrapper>
        <H1 lineHeight={'32px'}>Sign in</H1>
      </TitleWrapper>
      <SignInFormContainer>
        <Tabs noPadding noPaddingOnMobile inlineBlock>
          <Tab id="signIn" title={'Sign In'}>
            <SignInForm />
          </Tab>
          <Tab id="social" title={'GitHub/social'}>
            <SocialSignIn />
          </Tab>
        </Tabs>
        <SSOLinkWrapper>
          <SignInLink href="/signinsso">
            <Flex align="center" height="100%">
              <div>Sign in with SSO</div>
              <Margin bottom={2} left={6}>
                <Icon size={12} set={'icomoon'} type={'ds-new-window'} inline />
              </Margin>
            </Flex>
          </SignInLink>
        </SSOLinkWrapper>
      </SignInFormContainer>
      <StartTrialModule isMobile />
    </LeftContainerWrapper>
  </LeftContainer>
);