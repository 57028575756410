/** @format **/

import React from 'react';
import { IStyle } from 'fela';

import { createComponent } from 'react-fela';
import { Theme } from 'app/theme';

const StyledTabButtonContainer = (): IStyle => (
  {
    display: 'flex'
  }
);

export const TabButtonContainer = createComponent(StyledTabButtonContainer);
