/** @format */

import React, { FunctionComponent, ComponentClass } from 'react';
import { connect } from 'react-redux';

import { getActiveLayout } from 'selectors/router';
import { getActiveScreen } from 'selectors/router';

import { AuthenticationState } from './';

type StateProps = {
  Layout: ComponentClass | FunctionComponent;
  ActiveScreen: ComponentClass | FunctionComponent;
};

const DefaultLayout: FunctionComponent = ({ children }) => (
  <div style={{ height: '100%' }}>{children}</div>
);

const UnconnectedAuthentication: FunctionComponent<StateProps> = ({ Layout, ActiveScreen }) => {
  if (Layout === null) {
    Layout = DefaultLayout;
  }
  return <Layout>{ActiveScreen ? <ActiveScreen /> : null}</Layout>;
};

export const Authentication = connect<StateProps>((state: AuthenticationState) => ({
  Layout: getActiveLayout(state),
  ActiveScreen: getActiveScreen(state),
}))(UnconnectedAuthentication);
