/** @format */

import * as React from 'react';
import { combineReducers } from 'redux';

import AppFactory from 'app/index';
import { AppState } from 'interfaces/appState';

import * as SignIn from './signIn';

export type AuthenticationStateAtom = {
  authentication: {
    signIn: SignIn.State;
  };
};

export type AuthenticationState = AppState & AuthenticationStateAtom;

const AuthenticationReducer = combineReducers({
  signIn: SignIn.Reducer,
});

const { App, reducers } = AppFactory(
  'authentication',
  AuthenticationReducer,
  [SignIn.Saga],
  `/`,
  {
    ...SignIn.Navigation.Routes,
  },
  [SignIn.Navigation.Reducer],
  {},
);

export { App, reducers as AppReducers };
