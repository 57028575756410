/** @format **/

import React, { FunctionComponent, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'interfaces/appState';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Margin } from 'components/layout';
import { Tab, Tabs } from 'components/ds/tabs';

import { ContainersWrapper, RightSection, LeftSection, Layout } from '../components';

import { fetchSignInBanner } from '../actions';
import * as Selectors from '../selectors';
import { SignInBanner } from '../models';

type StateProps = {
  bannerData: SignInBanner;
};

type DispatchProps = {
  fetchSignInBanner: () => void;
};

type Props = StateProps & DispatchProps;

export const UnconnectedSignInScreen: FunctionComponent<Props> = props => {
  useEffect(() => {
    props.fetchSignInBanner();
  }, []);

  return (
    <Layout>
      {/* Height 100% required for IE */}
      <Flex style={{ minHeight: '100%' }}>
        <LeftSection />
        <RightSection bannerData={props.bannerData} />
      </Flex>
    </Layout>
  );
};

export const SignInScreen = connect<StateProps, DispatchProps, {}, AppState>(
  state => ({ bannerData: Selectors.getSignInBanner(state) }),
  (dispatch: Dispatch) => ({
    fetchSignInBanner: () => dispatch(fetchSignInBanner()),
  }),
)(UnconnectedSignInScreen);
