/** @format **/

import React from 'react';
import { IStyle } from 'fela';

import { createComponent } from 'react-fela';
import { Theme } from 'app/theme';
import { screenSmallOnly } from 'fela-rules/breakpoints';

const StyledTabNavigationContainer = (): IStyle => ({
  display: 'table', // Ensure the border expands 100% of the width
  borderBottom: `1px solid ${Theme.ds.getColorByName('blueGrey100')}`,
});

export const TabNavigationContainer = createComponent(StyledTabNavigationContainer);

type Props = {
  noPadding?: boolean;
  noPaddingOnMobile?: boolean;
  inlineBlock?: boolean;
};

const StyledTabNavigationOuterWrapper = (props: Props): IStyle => ({
  padding: props.noPadding ? '' : '0 24px',
  boxShadow: `inset 0 -1px 0 0 ${Theme.ds.getColorByName('blueGrey100')}`,
  display: props.inlineBlock ? 'inline-block' : '',

  ...screenSmallOnly({
    paddingLeft: props.noPaddingOnMobile ? '' : '16px',
    paddingRight: props.noPaddingOnMobile ? '' : '16px',
  }),
});

export const TabNavigationOuterWrapper = createComponent<Props>(StyledTabNavigationOuterWrapper);
