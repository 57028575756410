/** @format */

import './webpackPublicPath';
import './polyfills';

import * as React from 'react';
import { Middleware, Reducer } from 'redux';
import { get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { ReducerBuilder } from 'typescript-fsa-reducers';
import svg4everybody from 'svg4everybody';

import { RoutePreEntryCallback } from 'utils/routing';
import { setBaseUri } from 'utils/sagas';
import createReduxStoreForSection from 'app/store';

import { ErrorBoundary } from 'components/errorBoundary';

import { createRenderer } from './fela';

import { Providers } from './providers';
import { Chrome } from './chrome';
import { ScreenState } from './reducers';
import 'core-js/es6/map';
import 'core-js/es6/set';

svg4everybody();

const AppFactory = (
  sectionName: string,
  sectionReducers: Reducer<any>,
  sectionSagas: (() => SagaIterator)[],
  sectionBaseRoute: string,
  sectionRoutes: {
    [type: string]: { path: string; beforeEntryCallback?: RoutePreEntryCallback<any, any> };
  },
  sectionRouteReducers: ((
    builder: ReducerBuilder<ScreenState, ScreenState>,
  ) => ReducerBuilder<ScreenState, ScreenState>)[],
  options: {
    apiBaseUri?: string;
    extraTopLevelReducers?: { [key: string]: Reducer<any> };
    middleware?: Middleware[];
  } = {},
) => {
  // TODO: Move into store and use selector instead
  setBaseUri(get(options, 'apiBaseUri', ''));

  const { store, reducers } = createReduxStoreForSection(
    sectionName,
    sectionReducers,
    sectionSagas,
    sectionBaseRoute,
    sectionRoutes,
    sectionRouteReducers,
    get(options, 'extraTopLevelReducers', {}),
    get(options, 'middleware', []),
  );
  const renderer = createRenderer();

  const App: React.FunctionComponent<{}> = ({ children }) => (
    <Providers store={store} renderer={renderer}>
      <Chrome>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Chrome>
    </Providers>
  );

  return {
    App,
    reducers,
  };
};

export default AppFactory;
