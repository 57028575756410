/** @format **/

import React from 'react';
import { IStyle } from 'fela';

import { Theme } from 'app/theme';

import { SocialButtonType } from './models';
import { SocialButtonBase } from './styles';

export type SocialButtonProps = {
  socialType: SocialButtonType;
  link: string;
};

type Props = SocialButtonProps;

export const SocialButton: React.FunctionComponent<Props> = (props: Props) => {
  const getButtonColor = (socialButtonType: SocialButtonType) => {
    switch (socialButtonType) {
      case SocialButtonType.Github:
        return Theme.colors.social.github;

      case SocialButtonType.GooglePlus:
        return Theme.colors.social.google;
    }
  };

  const getPlatformName = (platform: string) => {
    return platform === 'GooglePlus' ? 'Google' : platform;
  };

  const buttonColor = getButtonColor(props.socialType);
  const platformName = getPlatformName(props.socialType);

  return (
    <SocialButtonBase href={props.link} backgroundColor={buttonColor}>
      {platformName}
    </SocialButtonBase>
  );
};
