/** @format **/

import { IStyle } from 'fela';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { screenSmallOnly, makeMediaQuery, breakpoints } from 'fela-rules/breakpoints';

import { Theme } from 'app/theme';

const StyledContainersWrapper = (): IStyle => ({
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
});
export const ContainersWrapper = createComponent(StyledContainersWrapper);

const StyledLeftContainer = (): IStyle => ({
  backgroundColor: Theme.colors.standard.white,
  minWidth: '520px',
  width: '50%',

  ...makeMediaQuery(
    {
      display: 'block',
      minWidth: '0',
      width: '100%',
    },
    breakpoints.screenSmall,
    1024,
  ),

  ...makeMediaQuery(
    {
      padding: '32px 64px 0 64px',
    },
    1024,
  ),

  ...makeMediaQuery(
    {
      padding: '24px',
    },
    breakpoints.screenMedium,
    1024,
  ),

  ...screenSmallOnly({
    padding: '24px 24px 0 24px',
  }),
});
export const LeftContainer = createComponentWithProxy(StyledLeftContainer);

const StyledLeftContainerWrapper = (): IStyle => ({
  maxWidth: '480px',
  margin: '0 auto',
});
export const LeftContainerWrapper = createComponentWithProxy(StyledLeftContainerWrapper);

const StyledSignInLink = (): IStyle => ({
  fontWeight: 600,
  color: Theme.ds.colors.link.inactive,
  [':hover' as any]: {
    color: Theme.ds.colors.link.active,
  },
  [':active' as any]: {
    color: Theme.ds.colors.link.active,
  },
  [':focus' as any]: {
    color: Theme.ds.colors.link.active,
  },
});
export const SignInLink = createComponentWithProxy(StyledSignInLink, 'a');

const StyledSignInLinkWrapper = (): IStyle => ({
  textAlign: 'center',
});
export const SignInLinkWrapper = createComponentWithProxy(StyledSignInLinkWrapper);

const StyledLogoImage = (): IStyle => ({
  width: '152px',
});
export const LogoImage = createComponentWithProxy(StyledLogoImage, 'img');

const StyledTitleWrapper = (): IStyle => ({
  marginTop: '96px',
  marginBottom: '32px',

  ...makeMediaQuery(
    {
      marginTop: '64px',
    },
    breakpoints.screenMedium,
    1024,
  ),

  ...screenSmallOnly({
    display: 'none',
  }),
});
export const TitleWrapper = createComponentWithProxy(StyledTitleWrapper, 'div');

const StyledSignInFormContainer = (): IStyle => ({
  position: 'relative',

  ...screenSmallOnly({
    marginTop: '24px',
  }),
});
export const SignInFormContainer = createComponentWithProxy(StyledSignInFormContainer);

const StyledSSOLinkWrapper = (): IStyle => ({
  alignItems: 'center',
  display: 'flex',
  height: '40px',
  position: 'absolute',
  top: 0,
  right: 0,
});
export const SSOLinkWrapper = createComponentWithProxy(StyledSSOLinkWrapper);

// Marketing image container styles ------------
type RightContainerProps = {
  darkTheme: boolean;
};

const StyledRightContainer = (props: RightContainerProps): IStyle => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: props.darkTheme ? '#001642' : 'unset',
  transition: 'background-color 0.3s ease-in',

  ...makeMediaQuery(
    {
      display: 'none',
    },
    breakpoints.screenSmall,
    1024,
  ),
});
export const RightContainer = createComponent(StyledRightContainer);

const StyledRightContainerWrapper = (): IStyle => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '2.4rem',
  maxWidth: '539px',
  width: '100%',
});
export const RightContainerWrapper = createComponent(StyledRightContainerWrapper);

const StyledSignInAdvertImage = (): IStyle => ({
  display: 'block',
  maxWidth: '437px',
  maxHeight: '437px',
  height: 'auto',
  width: '100%',
  margin: '0 auto',
});
export const SignInAdvertImage = createComponentWithProxy(StyledSignInAdvertImage, 'img');

const StyledSignInAdvertImagePlaceholder = (): IStyle => ({
  display: 'block',
  maxWidth: '437px',
  maxHeight: '437px',
  width: '100%',
  margin: '0 auto',
});
export const SignInAdvertImagePlaceholder = createComponentWithProxy(
  StyledSignInAdvertImagePlaceholder,
);

type DescriptionTextProps = {
  darkTheme: boolean;
};

const StyledDescriptionText = (props: DescriptionTextProps): IStyle => ({
  color: props.darkTheme ? Theme.ds.getColorByName('white') : Theme.ds.getColorByName('blueGrey600'),
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  maxWidth: '400px',
  marginLeft: 'auto',
  marginRight: 'auto',
});
export const DescriptionText = createComponent(StyledDescriptionText, 'p');