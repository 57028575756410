/** @format **/

import createActionFactory from 'typescript-fsa';

import { SignInBanner, NetworkSignInBanner } from '../models';

const createAction = createActionFactory('sign-in');

export const fetchSignInBanner = createAction('FETCH_SIGN_IN_BANNER');

export const updateSignInBanner = createAction<NetworkSignInBanner[]>('UPDATE_SIGN_IN_BANNER');

export const signInBannerFetchingStarted = createAction('SIGN_IN_BANNER_FETCHING_STARTED');
export const signInBannerFetchingSucceeded = createAction<SignInBanner>(
  'SIGN_IN_BANNER_FETCHING_SUCCEEDED',
);
export const signInBannerFetchingFailed = createAction<Error>('SIGN_IN_BANNER_FETCHING_FAILED');
