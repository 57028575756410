/** @format **/

import React, { useState } from 'react';

import { getAntiForgeryToken } from 'app/selectors/configuration';

import { Checkbox } from 'components/ds/selection';
import { EmphasisButton } from 'components/ds/button';
import { Input } from 'components/ds/inputs';
import { Flex } from 'components/flex';
import { Margin } from 'components/layout';
import { Icon } from 'components/icon';

import { getQueryStringParam } from 'utils/routing/queryString';
import { emailAddress } from 'utils/validation';

import { SignInLink, SignInLinkWrapper } from '../styles';

export const SignInForm: React.FunctionComponent = () => {
  const [isUnmaskPassword, toggleUnmaskPassword] = useState(false);
  const [rememberMeChecked, toggleRememberMeChecked] = useState(false);

  const antiForgeryToken = getAntiForgeryToken({});
  const returnSite = getQueryStringParam('returnsite');
  const returnUrl = getQueryStringParam('ReturnUrl');

  const [isEmailValid, setEmailValid] = useState(true);
  const checkEmail = (email: string) => setEmailValid(emailAddress(email));

  return (
    <form method="post" action="/signin" asp-antiforgery="false">
      <input type="hidden" value={antiForgeryToken} name="__RequestVerificationToken" />
      <input type="hidden" value={returnSite} name="ReturnSite" />
      <input type="hidden" value={returnUrl} name="ReturnUrl" />
      <Flex direction="column">
        <Margin top={32} bottom={24}>
          <Input
            type="email"
            error={isEmailValid ? null : 'Please enter a valid email address'}
            label="Email address"
            name="EmailAddress"
            onBlur={e => checkEmail(e.target.value)}
            tabIndex={1}
          />
        </Margin>
        <Margin bottom={26}>
          <Input
            type={isUnmaskPassword ? 'text' : 'password'}
            label="Password"
            name="Password"
            iconRight={
              <Icon
                set="flat"
                type="eye"
                dsColor={'blueGrey600'}
                onClick={() => toggleUnmaskPassword(!isUnmaskPassword)}
              />
            }
            tabIndex={2}
          />
        </Margin>
        <Margin bottom={18} left={'-12'}>
          <Checkbox
            checked={rememberMeChecked}
            onChange={() => toggleRememberMeChecked(!rememberMeChecked)}
            name="RememberMe"
            forForm
          >
            Remember me
          </Checkbox>
        </Margin>
        <EmphasisButton id="e2e-primary-confirm-button" type="submit" tabIndex={4}>
          Sign in
        </EmphasisButton>
        <Margin top={24} bottom={24}>
          <SignInLinkWrapper>
            <SignInLink id="e2e-forgot-password-link" href="/forgot_password" tabIndex={5}>
              Forgot your password?
            </SignInLink>
          </SignInLinkWrapper>
        </Margin>
      </Flex>
    </form>
  );
};
