/** @format **/

import React from 'react';

import { SignInLink } from '../styles';

import { StartTrialWrapper } from './styles';

type Props = {
  isMobile?: boolean;
};

export const StartTrialModule: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <StartTrialWrapper isMobile={props.isMobile}>
      Don't have an account?
      <SignInLink href="/signup"> Start trial</SignInLink>
    </StartTrialWrapper>
  );
};
