/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { screenSmallOnly } from 'fela-rules/breakpoints';

type SocialButtonBaseProps = {
  backgroundColor: string;
};

const StyledSocialButtonBase = (props: SocialButtonBaseProps): IStyle => ({
  backgroundColor: props.backgroundColor,
  border: 'none',
  borderRadius: '2px',
  color: 'white',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 600,
  marginBottom: '24px',
  padding: '16px',
  position: 'relative',
  textAlign: 'center',
  transition: 'background-color 200ms linear',
  width: '47%',

  [':hover' as any]: {
    color: 'white',
    opacity: 0.8,
  },
  [':active' as any]: {
    color: 'white',
    opacity: 0.8,
  },
  [':focus' as any]: {
    color: 'white',
  },
  ...screenSmallOnly({
    marginBottom: '16px',
    padding: '12px',
  }),
});
export const SocialButtonBase = createComponent(StyledSocialButtonBase, 'a', ['href']);
