/** @format **/

import { createSelector } from 'reselect';

import { AppState } from 'interfaces/appState';

import { SignInBanner } from '../models';

const signInSelector = (state: AppState) => state.authentication.signIn;

export const signInBanner = createSelector(
  signInSelector,
  signInBanner => signInBanner.banner,
);

export const getSignInBanner = createSelector(
  signInBanner,
  (signInBanner): SignInBanner => ({
    bannerId: signInBanner.bannerId,
    bannerImgSrc: signInBanner.bannerImgSrc,
    bannerImgHeader: signInBanner.bannerImgHeader,
    bannerImgDescription: signInBanner.bannerImgDescription,
    bannerLink: signInBanner.bannerLink,
    bannerLinkText: signInBanner.bannerLinkText,
    darkTheme: signInBanner.darkTheme,
  }),
);