/** @format **/

import React from 'react';
import { TabProps } from '../types';

type Props = {} & TabProps;

/**
 * A wrapper component which allows us to define a Tab
 * This doesn't get rendered directly
 * We use this component to define properties for building out a set of Tabs
 * The child elements of this function will be the content of the Tab pane
 */

export const Tab: React.FunctionComponent<Props> = ({ children }) => {
  return <div>{children}</div>;
};
