/** @format **/

import React from 'react';
import { IStyle } from "fela";
import { createComponent } from 'react-fela';

const StyledOverflowWrapper = (): IStyle => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollBehavior: 'smooth',
});

export const OverflowWrapper = createComponent(StyledOverflowWrapper, 'div', ['onScroll']);
