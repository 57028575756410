/**
 * @prettier
 */

import React, { Component } from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';
import { isNil } from 'lodash';

import { COLORS } from 'app/theme/materialDS';
import { ThemeType } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';
import { screenSmallOnly } from 'fela-rules/breakpoints';
import { Icon } from '../../../icon';

export type BaseAlertProps = {
  textColor: COLORS;
  backgroundColor: COLORS;
  borderColor?: COLORS;
  centerAlign?: boolean;
  renderAsBanner: boolean;
  onDismiss?: () => void;
  id?: string;
};

export type BaseDefaultPropKeys = {};

export const BaseDefaultProps: BaseDefaultPropKeys = {};

const StyledBaseAlert = (props: BaseAlertProps & ThemeType): IStyle =>
  applyModifiers(
    [
      props.renderAsBanner,
      {
        paddingTop: '12px',
        paddingBottom: '12px',
        minHeight: '48px',
        fontWeight: 600,
        zIndex: 50,
      },
    ],
    [
      !props.renderAsBanner,
      {
        paddingTop: '16px',
        paddingBottom: '16px',
        minHeight: '56px',
        borderRadius: '2px',
        fontWeight: 400,
      },
    ],
  )({
    // positioning
    position: 'relative',
    display: 'block',
    paddingLeft: '16px',
    paddingRight: '16px',
    // colors
    backgroundColor: props.theme.ds.getColorByName(props.backgroundColor),
    color: props.theme.ds.getColorByName(props.textColor),
    textAlign: props.centerAlign ? 'center' : 'left',
    border: props.borderColor
      ? `1px solid ${props.theme.ds.getColorByName(props.borderColor)}`
      : null,
    // fonts
    fontSize: '14px',
    lineHeight: '24px',
    ...screenSmallOnly({
      display: 'flex',
    }),
  });

const FelaBaseAlert = createComponent<BaseAlertProps>(StyledBaseAlert, 'div', ['id']);

type StyledAlertContentProps = {
  showDismissButton: boolean;
};
const StyledAlertContent = ({ showDismissButton }: StyledAlertContentProps): IStyle =>
  applyModifiers([
    showDismissButton,
    {
      marginRight: '40px',
      ...screenSmallOnly({
        marginRight: '32px',
      }),
    },
  ])({});

const FelaAlertContentContainer = createComponent(StyledAlertContent);

export class BaseAlert extends Component<BaseAlertProps> {
  render() {
    const { children, onDismiss, ...props } = this.props;
    const showDismissButton = !isNil(onDismiss);
    return (
      <FelaBaseAlert {...props}>
        <FelaAlertContentContainer showDismissButton={showDismissButton}>
          {children}
        </FelaAlertContentContainer>
        {showDismissButton && (
          <Icon
            onClick={onDismiss}
            set={'icomoon'}
            type={'cross'}
            dsColor={props.borderColor}
            inline
            styles={{
              position: 'absolute',
              right: '20px',
              top: '16px',
            }}
          />
        )}
      </FelaBaseAlert>
    );
  }
}
