/** @format **/

import { SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { get, defaultTo } from 'lodash';

import { AppState } from 'interfaces/appState';
import { createFetchSaga } from 'utils/sagas';
import { externalRequestFactory } from 'utils/fetching/utils';

import * as Actions from '../actions';
import { NetworkSignInBanner } from '../models';

const BlogUrl = defaultTo(
  get(get(window, 'RaygunConfiguration', {}), 'BlogUrl'),
  'https://raygun.com/blog',
);

const externalRequest = externalRequestFactory(BlogUrl, 'GET');

const fetchSignInBannerSaga = createFetchSaga<AppState>(
  {
    start: Actions.signInBannerFetchingStarted,
    done: Actions.signInBannerFetchingSucceeded,
    error: Actions.signInBannerFetchingFailed,
  },
  'sign-in-banner.json',
  {},
  externalRequest,
  { includeBaseName: false },
);
function* fetchSignInBanner() {
  yield call(fetchSignInBannerSaga as any);
}

function* forwardSignInBannerToStore() {
  while (true) {
    const action: Action<NetworkSignInBanner[]> = yield take(
      Actions.signInBannerFetchingSucceeded.type,
    );

    yield put(Actions.updateSignInBanner(action.payload));
  }
}

export function* Saga(): SagaIterator {
  yield takeLatest(Actions.fetchSignInBanner.type, fetchSignInBanner);
  yield fork(forwardSignInBannerToStore);
}