/** @format **/

import React from 'react';

import { Flex } from 'components/flex';
import { Padding } from 'components/layout';
import { InformationBox } from 'components/ds/alerts';

import { SocialButton, SocialButtonType } from '../socialButton';

export const SocialSignIn: React.FunctionComponent = () => {
  return (
    <Padding top={32} bottom={24}>
      <Padding bottom={32}>
        <InformationBox>
          <strong>Note:</strong> Social sign in is only available for legacy accounts.
        </InformationBox>
      </Padding>
      <Flex justify="space-between" wrap="wrap">
        <SocialButton socialType={SocialButtonType.Github} link="/oauth/githubextended?sso=True" />
        <SocialButton socialType={SocialButtonType.GooglePlus} link="/oauth/google?sso=True" />
      </Flex>
    </Padding>
  );
};
