/** @format **/

import React, { SetStateAction } from 'react';
import { find, isNil, isEmpty } from 'lodash';

import { Helptip } from 'components/ds/tooltip/helptip';

import { TabButton } from '../components/tabButton';
import { TabData } from '../types';

/**
 * getTabButtons generates an array of tab button components based on the state and data from parent component
 * @param tabData
 * @param activeTabId
 * @param setActiveTabId
 * @param withHelpTip
 * @param helpTextData
 */

export function getTabButtons(
  tabData: TabData[],
  activeTabId: string,
  setActiveTabId: (value: SetStateAction<string>) => void,
) {
  if (tabData.length === 0) {
    throw new Error('No tabs found');
  }

  return tabData.map(tab =>
    tab.helptip && tab.disabled ? (
      <Helptip
        key={tab.id}
        label={
          <>
            {tab.helptip.label}
            <br />
          </>
        }
        link={tab.helptip.link}
        linkText={tab.helptip.linkText}
        arrowPosition={tab.helptip.arrowPosition}
      >
        <TabButton
          key={tab.id}
          selected={activeTabId === tab.id}
          onClick={() => {
            if (tab.disabled) {
              return;
            }
            setActiveTabId(tab.id);
            const _ignored = !isNil(tab.onClick) && tab.onClick();
          }}
          disabled={tab.disabled}
        >
          {tab.title}
        </TabButton>
      </Helptip>
    ) : (
      <TabButton
        key={tab.id}
        selected={activeTabId === tab.id}
        onClick={() => {
          if (tab.disabled) {
            return;
          }
          setActiveTabId(tab.id);
          const _ignored = !isNil(tab.onClick) && tab.onClick();
        }}
        disabled={tab.disabled}
      >
        {tab.title}
      </TabButton>
    ),
  );
}

/**
 * isDefaultActiveTabDisabled checks if the tab which corresponds to the default active tab id supplied is currently disabled
 *
 * @param defaultActiveTabId
 * @param tabData
 */

export function isDefaultActiveTabDisabled(defaultActiveTabId: string, tabData: TabData[]) {
  if (isEmpty(tabData)) {
    return false;
  }

  if (isNil(defaultActiveTabId)) {
    return true;
  }

  const activeTab = find(tabData, t => t.id === defaultActiveTabId);

  if (isNil(activeTab)) {
    throw new Error(`Invalid tab ID: ${defaultActiveTabId}`);
  }

  return activeTab.disabled;
}
