/** @format **/

import React, { useState } from 'react';
import { find, pick } from 'lodash';

import { TabData } from './types';
import { TabButtonContainer, TabNavigationControl, TabPane, Tab } from './components';
import { getTabButtons, isDefaultActiveTabDisabled } from './utils';

type Props = {
  defaultActiveTabId?: string;
  noPadding?: boolean;
  noPaddingOnMobile?: boolean;
  inlineBlock?: boolean;
  withHelpTip?: boolean;
};

const Tabs: React.FunctionComponent<Props> = ({
  children,
  defaultActiveTabId,
  noPadding,
  noPaddingOnMobile,
  inlineBlock,
  withHelpTip,
}) => {
  const filteredChildren = React.Children.toArray(children).filter(child => React.isValidElement(child));

  /**
   *  If there are any coniditonally rendered tabs, remove the tabs that aren't returned as true to show
   */

  const tabData: TabData[] = filteredChildren.map((child: React.ReactElement) =>
    pick(child.props, ['id', 'title', 'children', 'disabled', 'onClick', 'helptip']),
  );

  /**
   * If the default tab is disabled or the default tab is not defined, we'll use the first tab in
   * the set which isn't disabled
   */
  const initialState = isDefaultActiveTabDisabled(defaultActiveTabId, tabData)
    ? find(tabData, t => !t.disabled).id
    : defaultActiveTabId;

  /**
   * Retrieve activeTabId out of the state
   * Used in two places:
   *  - Decide the selected tab button
   *  - Decide the content to show
   */

  const [activeTabId, setActiveTabId] = useState(initialState);
  const tabButtons = getTabButtons(tabData, activeTabId, setActiveTabId);

  const activeTabData = find(tabData, t => t.id === activeTabId);
  const activeContent = activeTabData.children;
  return (
    <>
      <TabNavigationControl
        noPadding={noPadding}
        noPaddingOnMobile={noPaddingOnMobile}
        withHelpTip={withHelpTip}
        inlineBlock={inlineBlock}
      >
        <TabButtonContainer>{tabButtons}</TabButtonContainer>
      </TabNavigationControl>
      <TabPane>{activeContent}</TabPane>
    </>
  );
};

export { Tabs, Tab };
