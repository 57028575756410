/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponentWithProxy } from 'react-fela';

import { screenMediumOnly } from 'fela-rules/breakpoints';
import { applyModifiers } from 'fela-rules/modifiers';

type Props = {
  mediumOnly: boolean;
  buttonsVisible: boolean;
};

const StyledNavButtonWrapper = (props: Props): IStyle =>
  applyModifiers([
    props.mediumOnly,
    {
      display: 'none',
      ...screenMediumOnly({
        display: props.buttonsVisible ? 'block' : 'none',
      }),
    },
  ])({});

export const NavButtonWrapper = createComponentWithProxy(StyledNavButtonWrapper);
