/** @format **/

import React from 'react';

import { HorizontalScroll } from 'components/ds/horizontalScroll';

import { TabNavigationContainer, TabNavigationOuterWrapper } from '../tabNavigationContainer';

type Props = {
  scrollStep?: number;
  noPadding?: boolean;
  noPaddingOnMobile?: boolean;
  inlineBlock?: boolean;
  withHelpTip?: boolean;
};

export const TabNavigationControl: React.FunctionComponent<Props> = ({
  children,
  scrollStep,
  noPadding,
  noPaddingOnMobile,
  inlineBlock,
  withHelpTip,
}) => (
  <TabNavigationOuterWrapper
    noPadding={noPadding}
    noPaddingOnMobile={noPaddingOnMobile}
    inlineBlock={inlineBlock}
  >
    {withHelpTip ? (
      <TabNavigationContainer>{children}</TabNavigationContainer>
    ) : (
      <HorizontalScroll scrollStep={scrollStep} mediumOnly>
        <TabNavigationContainer>{children}</TabNavigationContainer>
      </HorizontalScroll>
    )}
  </TabNavigationOuterWrapper>
);

TabNavigationControl.defaultProps = {
  scrollStep: 250,
};
