/**
 * Set's the `__webpack_public_path__` based on either the documents current script or 
 * By finding the `webpack/vendors` file and using that documents base url
 */
function getCurrentScriptDirectory() {
  let src;

  if(document.currentScript) {
    src = (document.currentScript as HTMLScriptElement).src;
  } else {
    // IE 11 support
    const scripts = document.getElementsByTagName('script');
    for(let i = 0; i < scripts.length; i++) {
      const s = scripts[i];
      if(s.src && s.src.indexOf('webpack/vendors')) {
        src = s.src;
        break;
      }
    }
  }

  if(!src) {
    throw new Error('Could not resolve default script.');
  }

  const length = src.lastIndexOf('/');
  return src.substring(0, length) + '/';
}

const isTestEnviroment = (window as any)["TESTING"];

if(!isTestEnviroment && !__webpack_public_path__) {
  __webpack_public_path__ = getCurrentScriptDirectory();
}