/** @format **/

import React from 'react';
import { createComponent } from 'react-fela';
import { IStyle } from 'fela';

import { Theme } from 'app/theme';
import { applyModifiers } from 'fela-rules/modifiers';

type TabButtonProps = {
  selected: boolean;
  disabled?: boolean;
};

const StyledTabButton = (props: TabButtonProps): IStyle =>
  applyModifiers(
    [
      props.selected,
      {
        boxShadow: `inset 0 -4px ${Theme.ds.getColorByName('blue900')}`,
        fontWeight: 600,
      },
    ],
    [
      props.disabled,
      {
        color: Theme.ds.getColorByName('grey400'),
        backgroundColor: Theme.ds.getColorByName('white'),
        cursor: 'default',
        boxShadow: 'none',
      },
    ],
    [
      props.selected || props.disabled,
      {
        ':hover': {
          backgroundColor: Theme.ds.getColorByName('white'),
        },
        ':active': {
          backgroundColor: Theme.ds.getColorByName('white'),
        },
      },
    ],
  )({
    color: Theme.ds.getColorByName('grey800'),
    padding: '0 16px',
    height: '40px',
    lineHeight: '40px',
    textAlign: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    ':hover': {
      backgroundColor: Theme.ds.getColorByName('grey50'),
    },
    ':active': {
      backgroundColor: Theme.ds.getColorByName('grey100'),
    },
  });

export const TabButton = createComponent(StyledTabButton, 'div', ['onClick']);
