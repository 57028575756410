/** @format **/

import React from 'react';
import { IStyle } from 'fela';
import { createComponent } from 'react-fela';

import { Theme } from 'app/theme';
import { screenMediumAndAbove, screenSmallOnly } from 'fela-rules/breakpoints';

type Props = {
  isMobile?: boolean;
};

const StyledStartTrialWrapper = (props: Props): IStyle => ({
  color: Theme.ds.colors.blueGrey.blueGrey300,
  display: 'static',
  textAlign: 'center',
  top: 0,
  right: 0,
  ...screenMediumAndAbove({
    display: props.isMobile ? `none` : `static`,
  }),
  ...screenSmallOnly({
    display: props.isMobile ? `static` : `none`,
  }),
});
export const StartTrialWrapper = createComponent(StyledStartTrialWrapper);
