/** @format **/

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as ChromeActions from 'app/actions/chrome';

import { ContainersWrapper } from './styles';

type StateProps = {};

type DispatchProps = {
  dispatch: Dispatch;
};

type PassedProps = {};

type Props = PassedProps & StateProps & DispatchProps;

const UnconnectedLayout: React.FunctionComponent<Props> = ({ children, dispatch }) => {
  useEffect(() => {
    dispatch(ChromeActions.setLayout('none'));
  });

  return <ContainersWrapper>{children}</ContainersWrapper>;
};

const ConnectedLayout = connect<StateProps, DispatchProps, PassedProps>(
  null,
  (dispatch: Dispatch) => ({
    dispatch,
  }),
)(UnconnectedLayout);

export { ConnectedLayout as Layout };
