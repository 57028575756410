/** @format **/

import { makeRouteMap, makeRoutingReducer } from 'utils/routing';

import { GoToSignInStep } from '../routing/authentication';

import * as Screens from './screens';

export const Routes = makeRouteMap(
  {
    [GoToSignInStep.type]: 'signIn',
  },
  '',
);

export const Reducer = makeRoutingReducer([[GoToSignInStep, Screens.SignInScreen]]);
